<template>
    <div class="quillEditorPosicao">
        <input
            type="file"
            @change="handleUploadImg"
            id="file"
            accept="image/png, image/gif, image/jpeg"
            hidden
        />

        <quillEditor
            ref="quillEdit"
            :value="valor"
            @input="$emit('input', $event)"
            :options="editorOption"
            class="editor"
        ></quillEditor>
    </div>
</template>

<script>
import { FormCampoRetornaDado } from '@/helpers/Componente.js';
import { quillEditor } from 'vue-quill-editor';
import ApiService from '@/services/ApiService.js';

export default {
    name: 'QuillEditor',
    props: {
        value: {
            type: String | null,
            default: ''
        },
        form: {
            type: Object
        },
        campo: {
            type: String
        }
    },

    components: {
        quillEditor
    },
    data: () => ({
        editorOption: {
            modules: {
                toolbar: {
                    container: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote', 'code-block'],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ script: 'sub' }, { script: 'super' }],
                        [{ indent: '-1' }, { indent: '+1' }],
                        [{ direction: 'rtl' }],
                        [{ size: ['small', false, 'large', 'huge'] }],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [{ font: [] }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ['clean'],
                        ['link', 'image', 'video']
                    ],
                    handlers: {
                        image: function() {
                            document.getElementById('file').click();
                        }
                    }
                },
                syntax: {
                    highlight: text => hljs.highlightAuto(text).value
                }
            }
        }
    }),
    methods: {
        dadosForm(campoSelecionado) {
            return FormCampoRetornaDado(
                this.form,
                this.campo,
                campoSelecionado
            );
        },

        async handleUploadImg(e) {
            this.selectedFile = e.target.files[0];

            var form = new FormData();
            form.append('imagem', this.selectedFile);

            await ApiService({
                method: 'POST',
                url: `V1/img/upload`,
                data: form,
                headers: {
                    contentType: 'multipart/form-data'
                }
            })
                .then(r => {
                    const range = this.$refs.quillEdit.quill.getSelection();

                    this.$refs.quillEdit.quill.insertEmbed(
                        range.index,
                        'image',
                        r.data.data.atributos.url
                    );
                })
                .catch(e => {
                    console.log(e);
                });
        }
    },
    computed: {
        valor: function() {
            return this.value != (null || false) ? this.value : '';
        }
    }
};
</script>

<style>
.quillEditorPosicao {
    display: flex;
    flex-direction: column;
}
.editor {
    height: 50rem;
}

.ql-editor > p > img {
    width: 300px;
}
</style>
